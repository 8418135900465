var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-toolbar html2canvas-ignore"},[_c('form',{attrs:{"data-html2canvas-ignore":""}},[_c('div',{staticClass:"form-group",staticStyle:{"position":"relative"}},[_c('div',[(_vm.contract && _vm.showDateTimePicker)?_c('DatetimeRangePicker',{attrs:{"startRef":_vm.dtIni,"endRef":_vm.dtEnd,"min":_vm.maxInterval,"uiConfig":_vm.toolbar.calendarUIConfig,"shortcuts":_vm.toolbar.calendarShortCuts,"allowFutureDate":false,"showRefreshIcon":_vm.endsNow,"isHistory":true},on:{"interval-changed":_vm.onIntervalChanged,"dateRangePickerEvent":_vm.onDateRangePickerEvent}}):_vm._e()],1)]),(_vm.dropdownOptions.length)?_c('div',{staticClass:"tab-toolbar-control"},[_c('dropdown',{class:_vm.selectedFilterLabels.length
            ? 'btn-primary btn-filter'
            : 'btn-filter',attrs:{"position":"right","data":_vm.dropdownOptions,"multiple":true,"showAll":true,"title":_vm.dropDownHint},on:{"apply":_vm.dropdownApply,"changed":_vm.onFilterChanged}},[(_vm.dropDownText)?_c('span',[_c('i',{staticClass:"fa fa-filter toolbar-btn-icon"}),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(" "+_vm._s(_vm.dropDownText)+" ")])]):_c('span',[_c('i',{staticClass:"fa fa-filter toolbar-btn-icon"})])])],1):_vm._e(),(_vm.media != 'print' && _vm.toolbar.downloadButton)?[_c('DownloadButton',{attrs:{"downloading":_vm.downloading,"xls":_vm.toolbar.downloadXLS,"csv":_vm.toolbar.downloadCSV},on:{"ready":function($event){return _vm.onDownloading(true)},"done":function($event){return _vm.onDownloading(false)}}})]:_vm._e(),(
        _vm.media == 'print' &&
        _vm.toolbar.preview &&
        (!_vm.$utils.isMobile() || _vm.toolbar.mobileNativePrintout)
      )?_c('div',{staticClass:"btn btn-default btn-local",class:this.mode == 'editor' || _vm.printStatus != 'idle' ? 'disabled' : '',on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.print.apply(null, arguments)}}},[(_vm.printStatus == 'idle')?_c('i',{staticClass:"fa fa-print"}):[_c('i',{staticClass:"fa fa-refresh fa-spin"}),(_vm.$store.getters.print)?_c('span',{staticClass:"badge",staticStyle:{"margin-left":"5px","background-color":"#666"}},[_vm._v(" "+_vm._s(_vm.printingPerc)+"% ")]):_vm._e()]],2):(
        (_vm.media != 'print' && _vm.toolbar.preview) ||
        !_vm.$utils.isMobile() ||
        _vm.toolbar.mobileNativePrintout
      )?_c('div',{staticClass:"btn btn-default btn-local",class:this.mode == 'editor' ? 'disabled' : '',attrs:{"title":_vm.$t('preview')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.preview.apply(null, arguments)}}},[_c('i',{staticClass:"hidden-xs fa fa-file-text-o"}),_c('i',{staticClass:"hidden-sm hidden-md hidden-lg",class:_vm.toolbar.mobileNativePrintout ? 'fa fa-print' : 'fa fa-file-text-o'})]):_vm._e(),(_vm.showCustomToolbar)?_c('div',{staticClass:"custom-panel-toolbar"},[_vm._t("toolbar")],2):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }